<div class="layout-wrapper layout-sidebar">
  <div class="layout-main">
    <app-menu></app-menu>
    <p-toast></p-toast>
    <app-breadcrumbs></app-breadcrumbs>

    <div class="layout-main-content">
      <router-outlet></router-outlet>
    </div>

    <app-footer></app-footer>
  </div>
</div>
