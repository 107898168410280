import {Component, ViewChild} from '@angular/core';
import {UserModel} from '../../models/user.model';
import {catchError, forkJoin, of} from "rxjs";
import {SubsidiaryUserLinkModel} from "../../models/subsidiary-user-link.model";
import {SubsidiaryService} from "../../services/subsidiary.service";
import {SubsidiaryModel} from "../../models/subsidiary.model";
import {HttpErrorResponse} from "@angular/common/http";
import {SwalComponent} from "@sweetalert2/ngx-sweetalert2";
import {SubsidiaryUserLinkService} from "../../services/subsidiary-user-link.service";

@Component({
  selector: 'app-links-to-subsidiaries',
  templateUrl: './links-to-subsidiaries.component.html',
  styleUrls: ['./links-to-subsidiaries.component.scss']
})
export class LinksToSubsidiariesComponent {
  public subsidiaries: SubsidiaryModel[];
  public userSubsidiaries: SubsidiaryModel[];
  public linksToSubsidiariesValueMap = [];
  public apiRequestErrorText: string;
  @ViewChild('apiRequestErrorSwal') private apiRequestErrorSwal: SwalComponent;
  protected user: UserModel = null;
  protected loading: boolean = false;

  constructor(
    protected subsidiaryService: SubsidiaryService,
    protected subsidiaryUserLinkService: SubsidiaryUserLinkService
  ) {
  }

  /**
   * Load the Subsidiary-links for the given user.
   */
  loadLinksToSubsidiariesForUser(user: UserModel) {
    this.loading = true;
    this.user = user;

    forkJoin([
      this.subsidiaryService.getSubsidiaries(),
      this.subsidiaryService.getSubsidiaries(user)
    ]).subscribe(result => {
      this.subsidiaries     = result[0];
      this.userSubsidiaries = result[1];

      this.subsidiaries.forEach((subsidiary) => {
        this.linksToSubsidiariesValueMap[subsidiary.getId()] = false;
      });

      this.userSubsidiaries.forEach(userSubsidiary => {
        this.linksToSubsidiariesValueMap[userSubsidiary.getId()] = true;
      });

      this.loading = false;
    });
  }

  toggleLinkToSubsidiary(subsidiary: SubsidiaryModel, $event): void {
    this.loading = true;

    // Add user to subsidiary
    if ($event.checked) {
      this.subsidiaryUserLinkService.addUserToSubsidiary(this.user, subsidiary)
        .pipe(
          catchError(error => {
            this.loading = false;

            this.apiRequestErrorSwal.html = error instanceof HttpErrorResponse && error.error.status < 500 ?
              error.error.detail : 'Couldn\'t link User to Subsidiary.';

            this.apiRequestErrorSwal.fire().then();
            return of<SubsidiaryUserLinkModel>();
          })
        )
        .subscribe(() => {
          this.loading = false;
          this.linksToSubsidiariesValueMap[subsidiary.getId()] = true;
          this.userSubsidiaries.push(subsidiary);
        });
    } else {
      // Remove user from subsidiary
      this.userSubsidiaries.forEach(userSubsidiary => {
        if (userSubsidiary.getId() === subsidiary.getId()) {
          this.subsidiaryUserLinkService.removeUserFromSubsidiary(this.user, subsidiary)
            .pipe(
              catchError(error => {
                this.loading = false;

                this.apiRequestErrorSwal.html = error instanceof HttpErrorResponse && error.error.status < 500 ?
                  error.error.detail : 'Couldn\'t detach User from Subsidiary.';

                this.apiRequestErrorSwal.fire().then();
                return of<SubsidiaryUserLinkModel>();
              })
            )
            .subscribe(() => {
              this.loading = false;
              this.linksToSubsidiariesValueMap[subsidiary.getId()] = false;
            });
          return;
        }
      });
    }
  }
}
