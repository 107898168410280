import {Injectable} from "@angular/core";
import {Permission} from "../models/permission";

@Injectable()
export class MenuService {

  public menu: any[] = [
    {
      label: 'Home',
      icon: 'pi pi-home',
      routerLink: ['']
    },
    {
      label: "Users",
      icon: 'pi pi-user',
      routerLink: ['users']
    },
    // {
    //   label: "Validate Orders",
    //   icon: 'pi pi-check',
    //   routerLink: ['validate-sos']
    // },
    // {
    //   label: "Validate Inputs",
    //   icon: "pi pi-check",
    //   routerLink: ['validate-input']
    // },
    // {
    //   label: "Account Settings",
    //   icon: "pi pi-cog",
    //   routerLink: ['account-settings']
    // },
    // {
    //   label: 'Upload Inputs',
    //   icon: "pi pi-file",
    //   routerLink: ['upload-inputs']
    // },
    // {
    //   label: "Products",
    //   icon: "pi pi-shopping-bag",
    //   routerLink: ['product-accounts']
    // },
    // {
    //   label: "Invoices",
    //   icon: "pi pi-credit-card",
    //   routerLink: ['invoices']
    // }
  ]

}
