import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { KeycloakAuthGuard, KeycloakService } from 'keycloak-angular';
import {Permission} from "../models/permission";
import {from} from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class KAuthGuard extends KeycloakAuthGuard {

  private _permissions: any;

  constructor(
    protected override readonly router: Router,
    protected readonly keycloak: KeycloakService
  ) {
    super(router, keycloak);
  }

  override canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean | UrlTree> {
    if (this._permissions) {
      if (route.data && route.data['permission']) {
        return route.data['permission'].some((p:any) => this._permissions.includes(p));
      }
    }

    return super.canActivate(route, state);
  }

  async isAccessAllowed(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Promise<boolean | UrlTree> {

    if (!this.authenticated) {
      from(this.keycloak.login({
        redirectUri: window.location.origin + state.url,
      })).subscribe( () => {
        this._permissions = this.keycloak.getUserRoles()
      });
    } else {
      this._permissions = this.keycloak.getUserRoles()
    }

    return this.authenticated;
  }
  checkUserForPermission(
    permissions: string[]
  ): boolean {
    //check for token permissions
    return permissions.some(p => this._permissions.includes(p));
  }
}
