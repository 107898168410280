import {HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {environment} from "../environments/environment";

@Injectable()
export class BaseUrlInterceptor implements HttpInterceptor {
  constructor() { }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<any> {
    req = req.clone({ url: environment.apiUrl + req.url });
    return next.handle(req);
  }
}
