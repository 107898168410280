<ng-container *ngIf="!item.permissions || guard.checkUserForPermission(item.permissions)">
  <a *ngIf="item.children" (click)="active = !active">
    <i class="layout-menuitem-icon" [class]="item.icon" *ngIf="item.icon"></i>
    <span class="layout-menuitem-text">{{item.label}}</span>
    <i class="pi pi-fw pi-angle-down layout-submenu-toggler"></i>
  </a>
  <a *ngIf="!item.children"
     routerLinkActive="active-menuitem-routerlink" [routerLink]="item.routerLink" [routerLinkActiveOptions]="{exact: true}">
    <i class="layout-menuitem-icon" [class]="item.icon" *ngIf="item.icon"></i>
    <span class="layout-menuitem-text">{{item.label}}</span>

    <i class="pi pi-fw pi-angle-down layout-submenu-toggler" *ngIf="item.children"></i>
  </a>

  <ul *ngIf="item.children && active" [@children]="active?'visibleAnimated':'hiddenAnimated'">
    <ng-container *ngFor="let item of item.children">
      <li app-menuitem [item]="item"></li>
    </ng-container>
  </ul>
</ng-container>
