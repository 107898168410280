import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {map, Observable} from 'rxjs';
import {UserModel} from '../models/user.model';
import {SubsidiaryModel} from "../models/subsidiary.model";

@Injectable({
  providedIn: 'root'
})
export class SubsidiaryUserLinkService {

  constructor(
    private httpClient: HttpClient
  ) { }

  /**
   * Add the given user to the subsidiary
   */
  addUserToSubsidiary(user: UserModel, subsidiary: SubsidiaryModel): Observable<any> {
    const body = {
      userId: user.getId(),
      subsidiaryId: subsidiary.getId()
    };

    return this.httpClient.post<any>('/add-user-to-subsidiary', body);
  }

  /**
   * Remove the given user from the subsidiary
   */
  removeUserFromSubsidiary(user: UserModel, subsidiary: SubsidiaryModel): Observable<any> {
    const body = {
      userId: user.getId(),
      subsidiaryId: subsidiary.getId()
    };

    return this.httpClient.post<any>('/remove-user-from-subsidiary', body);
  }
}
