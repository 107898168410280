import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {map, Observable} from 'rxjs';
import {UserModel} from '../models/user.model';
import {Collection} from '../models/helpers/collection';
import * as classTransformer from 'class-transformer';

@Injectable({
  providedIn: 'root'
})
export class UserService {
  USERS_URI = '/users';

  constructor(
    private httpClient: HttpClient
  ) { }

  getUsers(): Observable<UserModel[]> {
    return this.httpClient
      .get<Collection<UserModel>>(this.USERS_URI)
      .pipe(map((result: any) => {
        return result._embedded.users.map((userData: object) => this.hydrateUser(userData));
      }));
  }

  protected hydrateUser(userData: object): UserModel {
    return classTransformer.plainToInstance(UserModel, userData);
  }
}
