export class SubsidiaryModel {
  constructor(id: string, name: string) {
    this.id   = id;
    this.name = name;
  }

  protected id: string;
  protected name: string;

  public getId(): string {
    return this.id;
  }

  public getName(): string {
    return this.name;
  }
}
