import {Component, OnInit, Injectable} from '@angular/core';
import {MenuItem} from "primeng/api";
import {MenuService} from "../../services/menu.service";
import {NavigationEnd, Router} from "@angular/router";

@Injectable()
@Component({
  selector: 'app-breadcrumbs',
  templateUrl: './breadcrumbs.component.html',
  styleUrls: ['./breadcrumbs.component.scss']
})
export class BreadcrumbsComponent implements OnInit {
  private _sourceItems: any[];
  public items: MenuItem[] = [];

  constructor(
    private menuService: MenuService,
    private router: Router
  ) {
    this._sourceItems = this.menuService.menu;

    this.router.events.subscribe(NavigationEnd => {
      this.items = [];
      this.updateBreadcrumbs(this._sourceItems);
    })
  }

  ngOnInit(): void {
    this.items = [];
    this.updateBreadcrumbs(this._sourceItems);
    // this.items = this.menuService.menu;
  }

  /**
   * Recursively parse navigation tree, if active add to breadcrumbs
   * @param items
   */
  updateBreadcrumbs(items: any): void {
    if (items) {
      items.forEach((item: any) => {
        if (this.router.isActive(item.routerLink[0], false)) {
          let newItem: MenuItem = {...item};
          delete newItem.icon;
          this.items.push(newItem);

          if (item.children) this.updateBreadcrumbs(item.children);
        }
      });
    }
  }
}
