<div class="grid">
  <div class="col-12">
    <div class="card">
      <h5>User List</h5>
      <p-table #dt
               [value]="users"
               dataKey="id"
               [rows]="10"
               [loading]="loading"
               [rowHover]="true"
               styleClass="p-datatable-striped"
               [paginator]="true"
               [globalFilterFields]="['username', 'email']"
               currentPageReportTemplate="Showing {first} to {last} of {totalRecords} Users"
               [showCurrentPageReport]="true"
               responsiveLayout="scroll"
      >
        <ng-template pTemplate="caption">
          <div class="flex justify-content-between flex-column sm:flex-row">
            <button pButton label="Clear" class="p-button-outlined mb-2" icon="pi pi-filter-slash" (click)="clear(dt)"></button>
            <span class="p-input-icon-left mb-2">
							<i class="pi pi-search"></i>
							<input pInputText type="text" #filter (input)="dt.filterGlobal($event.target.value, 'contains')" placeholder="Search Keyword" class="w-full"/>
						</span>
          </div>
        </ng-template>
        <ng-template pTemplate="header">
          <tr>
            <th>
              <div class="flex justify-content-between align-items-center">
                Username
                <p-columnFilter
                  type="text"
                  field="username"
                  display="menu"
                  placeholder="Search by Username"
                  [showMatchModes]="false"
                  [showAddButton]="false"
                  [showOperator]="false"
                ></p-columnFilter>
              </div>
            </th>
            <th>
              <div class="flex justify-content-between align-items-center">
                Email Address
                <p-columnFilter type="text" field="email" display="menu" placeholder="Search by Email Address"></p-columnFilter>
              </div>
            </th>
            <th pSortableColumn="created">Created <p-sortIcon field="created"></p-sortIcon></th>
            <th>Roles</th>
            <th>Links to Subsidiaries</th>
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-user>
          <tr>
            <td style="min-width: 12rem;">
              {{ user.getUsername() }}
            </td>
            <td style="min-width: 12rem;">
              {{ user.getEmail() }}
            </td>
            <td style="min-width: 14rem;">
              {{ user.getCreated() | date: 'dd.MM.yyyy HH:mm' }}
            </td>
            <td style="min-width: 10rem;">
              {{ user.getRoles().join(', ') }}
            </td>
            <td>
              <button pButton type="button" class="p-button-secondary" icon="pi pi-cog" (click)="showLinksToSubsidiaries(user)"></button>
            </td>
          </tr>
        </ng-template>
        <ng-template pTemplate="emptymessage">
          <tr>
            <td colspan="7">No Users found.</td>
          </tr>
        </ng-template>
        <ng-template pTemplate="loadingbody">
          <tr>
            <td colspan="7">Loading Users. Please wait.</td>
          </tr>
        </ng-template>
      </p-table>
    </div>
  </div>
</div>

<p-dialog modal="true" [style]="{width: '30vw'}" [(visible)]="userInSubsidiaryLinksModal">
  <ng-template pTemplate="header">
    <h4>Links to Subsidiaries</h4>
  </ng-template>

  <app-links-to-subsidiaries #linksToSubsidiariesModal></app-links-to-subsidiaries>

  <ng-template pTemplate="footer">
    <p-button label="Close" icon="pi pi-times" styleClass="p-button-secondary" (click)="closeLinksToSubsidiaries()"></p-button>
  </ng-template>
</p-dialog>
