<p-table
  [value]="subsidiaries"
  styleClass="p-datatable-striped"
  dataKey="id"
  [loading]="loading"
  [rowHover]="true"
>
  <ng-template pTemplate="body" let-subsidiary>
    <tr>
      <td>{{ subsidiary.getName() }}</td>
      <td><p-inputSwitch [(ngModel)]="linksToSubsidiariesValueMap[subsidiary.getId()]" (onChange)="toggleLinkToSubsidiary(subsidiary, $event)"></p-inputSwitch></td>
    </tr>
  </ng-template>
</p-table>

<swal
  #apiRequestErrorSwal
  title="Error"
  icon="error"
  [html]="apiRequestErrorText"
  confirmButtonText="Close"
>
</swal>
