import { Injectable } from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {map, Observable} from "rxjs";
import {UserModel} from "../models/user.model";
import {Collection} from "../models/helpers/collection";
import * as classTransformer from "class-transformer";
import {SubsidiaryModel} from "../models/subsidiary.model";

@Injectable({
  providedIn: 'root'
})
export class SubsidiaryService {
  SUBSIDIARIES_URI = '/subsidiaries';

  constructor(
    private httpClient: HttpClient
  ) { }

  getSubsidiaries(user?: UserModel): Observable<SubsidiaryModel[]> {
    let url = this.SUBSIDIARIES_URI;

    if (user) {
      url += '?user=' + user.getId();
    }

    return this.httpClient
      .get<Collection<SubsidiaryModel>>(url)
      .pipe(map((result: any) => {
        return result._embedded.subsidiaries.map((subsidiaryData: object) => this.hydrateSubsidiary(subsidiaryData));
      }));
  }

  protected hydrateSubsidiary(subsidiaryData: object): SubsidiaryModel {
    return classTransformer.plainToInstance(SubsidiaryModel, subsidiaryData);
  }
}
