import {Component, ElementRef, Input, OnInit, ViewChild, ViewContainerRef} from '@angular/core';
import {UserService} from '../../services/user.service';
import {UserModel} from '../../models/user.model';
import {Table} from 'primeng/table';
import {LinksToSubsidiariesComponent} from "../links-to-subsidiaries/links-to-subsidiaries.component";

@Component({
  selector: 'app-users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.scss']
})
export class UsersComponent implements OnInit {

  protected users: UserModel[] = [];
  protected loading: boolean = false;
  @ViewChild('dt') table: Table;
  @ViewChild('filter') filter: ElementRef;
  userInSubsidiaryLinksModal: UserModel = null;
  @ViewChild(LinksToSubsidiariesComponent) linksToSubsidiariesModal: LinksToSubsidiariesComponent;

  constructor(
    protected userService: UserService
  ) {
  }

  ngOnInit() {
    this.userService.getUsers().subscribe(result => {
      this.users = result;
    });
  }

  clear(table: Table) {
    table.clear();
    this.filter.nativeElement.value = '';
  }

  showLinksToSubsidiaries(user: UserModel) {
    this.linksToSubsidiariesModal.loadLinksToSubsidiariesForUser(user);
    this.userInSubsidiaryLinksModal = user;
  }

  closeLinksToSubsidiaries() {
    this.userInSubsidiaryLinksModal = null;
  }
}
