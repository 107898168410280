import {Component, Input, OnInit} from '@angular/core';
import {animate, state, style, transition, trigger} from "@angular/animations";
import {NavigationEnd, Router} from "@angular/router";
import {filter} from "rxjs";
import {PermissionsGuard} from "../../guards/permissions.guard";
import {KAuthGuard} from "../../guards/auth.guard";
import {MenuItem} from "primeng/api";

@Component({
  selector: '[app-menuitem]',
  templateUrl: './menu-item.component.html',
  styleUrls: ['./menu-item.component.scss'],
  host: {
    '[class.active-menuitem]': 'active'
  },
  animations: [
    trigger('children', [
      state('void', style({
        height: '0px'
      })),
      state('hiddenAnimated', style({
        height: '0px'
      })),
      state('visibleAnimated', style({
        height: '*'
      })),
      state('visible', style({
        height: '*',
        'z-index': 100
      })),
      state('hidden', style({
        height: '0px',
        'z-index': '*'
      })),
      transition('visibleAnimated => hiddenAnimated', animate('400ms cubic-bezier(0.86, 0, 0.07, 1)')),
      transition('hiddenAnimated => visibleAnimated', animate('400ms cubic-bezier(0.86, 0, 0.07, 1)')),
      transition('void => visibleAnimated, visibleAnimated => void', animate('400ms cubic-bezier(0.86, 0, 0.07, 1)'))
    ])
  ]
})
export class MenuItemComponent implements OnInit {
  @Input() public item : any;
  public active: boolean = false;

  constructor(
    private router: Router,
    public guard: KAuthGuard
  ) {
    this.router.events.pipe(filter(event => event instanceof NavigationEnd))
      .subscribe(params => {
        this.updateActiveStateFromRoute();
      });
  }
  ngOnInit(): void {
    this.updateActiveStateFromRoute();
  }
  updateActiveStateFromRoute() {
    this.active = this.router.isActive(this.item.routerLink[0], this.item.children ? false : true);
  }
}
