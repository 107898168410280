export const environment = {
  production: false,
  name: 'User Management',
  longname: 'BayWa r.e. LUSY - User Management',
  organization: 'BayWa r.e. Solar Systems S.à r.l.',
  apiUrl: 'https://api-staging.baywa-lusy.com',
  authServerAddress: 'https://auth-dev.baywa-lusy.com',
  authRedirectUri: 'https://users-staging.baywa-lusy.com',
  authRealm: 'master',
  authClientId: 'users-frontend'
};
