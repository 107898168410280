<div class="layout-menu-wrapper" [ngClass]="wideMenu?'layout-sidebar-active':''" (mouseover)="wideMenu=true"
     (mouseout)="wideMenu=false">
  <div class="menu-logo">
    <a href="#" class="logo">
      <img [src]="'assets/baywarelusy/images/Logo_BayWar-re_B_neg.svg'" alt="Logo">
    </a>

    <a href="#" class="app-name">
      <img [src]="'assets/baywarelusy/images/Logo_BayWar-re_D_neg.svg'" alt="{{'longname' | env}}"/>
    </a>
  </div>

  <div class="layout-menu-container">
    <ul class="layout-menu">
      <ng-container *ngFor="let item of items">
        <li app-menuitem *ngIf="!item.separator" [item]="item"></li>
        <li *ngIf="item.separator" class="menu-separator"></li>
      </ng-container>
    </ul>
  </div>

  <div class="layout-inline-menu">
    <app-inlinemenu></app-inlinemenu>
  </div>
</div>
