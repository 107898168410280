import {Transform} from 'class-transformer';
import {DatePipe} from '@angular/common';

export class UserModel {

  constructor(id: string, username: string, email: string, emailVerified: boolean, roles: string[], created: Date) {
    this.id = id;
    this.username = username;
    this.email = email;
    this.emailVerified = emailVerified;
    this.roles = roles;
    this.created = created;
  }

  protected id: string;
  protected username: string;
  protected email: string;
  protected emailVerified: boolean;
  protected roles: string[];
  @Transform(({ value }) => {
    return value instanceof Date ? (new DatePipe('de-LU')).transform(value, 'dd/MM/yyyy') : new Date(value);
  })
  protected created: Date;

  public getId(): string {
    return this.id;
  }

  public getUsername(): string {
    return this.username;
  }

  public getEmail(): string {
    return this.email;
  }

  public getCreated(): Date {
    return this.created;
  }

  public getRoles(): string[] {
    return this.roles;
  }
}
