import {APP_INITIALIZER, NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';
import {HTTP_INTERCEPTORS, HttpClientModule} from "@angular/common/http";
import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {FooterComponent} from './components/footer/footer.component';
import {MenuComponent} from './components/menu/menu.component';
import {MenuItemComponent} from './components/menu-item/menu-item.component';
import {KeycloakService, KeycloakAngularModule} from "keycloak-angular";
import {AppMainComponent} from './components/app-main/app-main.component';
import {BreadcrumbsComponent} from './components/breadcrumbs/breadcrumbs.component';
import {AppInlineMenuComponent} from './components/app-inline-menu/app-inline-menu.component';
import {EnvPipe} from './helpers/env.pipe';
import {TableModule} from "primeng/table";
import {ScrollerModule} from "primeng/scroller";
import {DropdownModule} from "primeng/dropdown";
import {MultiSelectModule} from "primeng/multiselect";
import {ToastModule} from "primeng/toast";
import {MessageService} from "primeng/api";
import {BreadcrumbModule} from "primeng/breadcrumb";
import {InputTextModule} from "primeng/inputtext";
import {ButtonModule} from "primeng/button";
import {MessagesModule} from 'primeng/messages';
import {ImageModule} from "primeng/image";
import {MenuService} from "./services/menu.service";
import {initializeKeycloak} from "./init/keycloak-init.factory";
import {BrowserAnimationsModule} from "@angular/platform-browser/animations";
import {CalendarModule} from "primeng/calendar";
import {RippleModule} from "primeng/ripple";
import {DialogModule} from "primeng/dialog";
import {SelectButtonModule} from "primeng/selectbutton";
import {FormsModule} from "@angular/forms";
import {TagModule} from "primeng/tag";
import {CheckboxModule} from "primeng/checkbox";
import {ProgressBarModule} from "primeng/progressbar";
import {FileUploadModule} from "primeng/fileupload";
import {ChipsModule} from "primeng/chips";
import {TriStateCheckboxModule} from "primeng/tristatecheckbox";
import {HomepageComponent} from './components/homepage/homepage.component';
import {KnobModule} from "primeng/knob";
import {CardModule} from "primeng/card";
import {UsersComponent} from './components/users/users.component';
import {BaseUrlInterceptor} from "./base-url.interceptor";
import {SliderModule} from "primeng/slider";
import {LinksToSubsidiariesComponent} from './components/links-to-subsidiaries/links-to-subsidiaries.component';
import {InputSwitchModule} from "primeng/inputswitch";
import {SweetAlert2Module} from "@sweetalert2/ngx-sweetalert2";


@NgModule({
  declarations: [
    AppComponent,
    FooterComponent,
    MenuComponent,
    MenuItemComponent,
    AppMainComponent,
    BreadcrumbsComponent,
    AppInlineMenuComponent,
    EnvPipe,
    HomepageComponent,
    UsersComponent,
    LinksToSubsidiariesComponent
  ],
  imports: [
    KeycloakAngularModule,
    BrowserModule,
    AppRoutingModule,
    TableModule,
    BrowserAnimationsModule,
    HttpClientModule,
    DropdownModule,
    MultiSelectModule,
    ToastModule,
    BreadcrumbModule,
    InputTextModule,
    ButtonModule,
    MessagesModule,
    ImageModule,
    CalendarModule,
    RippleModule,
    DialogModule,
    SelectButtonModule,
    FormsModule,
    ScrollerModule,
    TagModule,
    CheckboxModule,
    ProgressBarModule,
    FileUploadModule,
    ChipsModule,
    TriStateCheckboxModule,
    KnobModule,
    CardModule,
    SliderModule,
    InputSwitchModule,
    SweetAlert2Module.forRoot(),
  ],
  providers: [
    {
      provide: APP_INITIALIZER,
      useFactory: initializeKeycloak,
      multi: true,
      deps: [KeycloakService],
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: BaseUrlInterceptor,
      multi: true
    },
    BreadcrumbsComponent,
    MenuService,
    MessageService,
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
