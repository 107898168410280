import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {AppMainComponent} from "./components/app-main/app-main.component";
import {KAuthGuard} from "./guards/auth.guard";
import {HomepageComponent} from "./components/homepage/homepage.component";
import {UsersComponent} from "./components/users/users.component";

const routes: Routes = [
  {
    path: '', component: AppMainComponent, children: [
      { path: '', component: HomepageComponent, canActivate: [KAuthGuard] },
      { path: 'users', component: UsersComponent, canActivate: [KAuthGuard]},
    ]
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {scrollPositionRestoration: 'enabled'})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
